<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Terms and Conditions</h2>
            <!-- <p>Our latest news</p> -->
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details">
                    <div class="article-content">
                        <h3 style="font-weight: bold">TERMS AND CONDITIONS</h3>



                        <p> RUPEEMOJO TECHNOLOGIES PRIVATE LIMITED, whose registered office is situated at 1-7-135/12, 13/1, Zamistanpur, Musheerabad, Hyderabad - 500020, India (hereinafter shall be referred to as "rupeemojo" or "Company" or "us" or "we"
                            are the owner of the mobile application by the name rupeemojo ("App") and website at www.rupeemojo.com ("Website") (App and Website are hereinafter together referred to as the "Platform"). </p>


                        <p>As a user ("User" or "You") of the Platform, You understand that Platform provides access between multiple Users in the form of consumers, financial institutions, data partners and other partners lenders, borrowers, service providers
                            etc. with a view to facilitate lending and borrowing activities, including providing other financial products i.e. insurance products, credit cards etc. between the Users. </p>


                        <p>It is hereby clarified that the term "Users" shall mean any person using the Platform i.e. consumers, financial institutions, regulated entities, data partners and other partners. Your use of this Platform implies that You have
                            read, understood and agreed to abide by following, disclaimer, terms & conditions ("Terms & Conditions"). </p>


                        <p>These Terms & Conditions read together with our privacy policy govern Your usage of the Platform and the terms for the purchase of the Products from the Platform (“Product”). If You disagree with any part of these Terms & Conditions,
                            please do not use the Platform. </p>


                        <p>Please read these Terms & Conditions carefully before accessing or using our Platform. By accessing or using any part of the Platform, You agree to be bound by these Terms & Conditions. If You do not agree to all the terms and
                            conditions of this agreement, then You may not access the Platform or use any services provided by Us. </p>


                        <h3 style="font-weight: bold">1. GENERAL </h3>

                        <p>The App provides access to an online platform bringing together consumers, financial institutions, data partners and other partners willing to abide by the Terms & Conditions herein. rupeemojo is merely a facilitator providing
                            the Platform for any person in need of financial products. rupeemojo provides various kind of personal loan products as specifically detailed under Clause 2 to its Users. </p>


                        <p>rupeemojo is merely facilitates a transaction between two such persons (i.e. Users). rupeemojo is not an organization registered with the Reserve Bank of India and does not hold any license to engage in any activities relating
                            to lending or borrowing. rupeemojo is not a Financial Institution under the Companies Act, 2013 or the Banking Regulation Act, 1949 or any other laws for the time being in force in India. rupeemojo is also not a deposit taking
                            company or a chit fund or a company offering any investment schemes as per the rules and regulations for the time being in force in India. </p>


                        <p>rupeemojo is a service provider facilitating the access between financial institutes and non-banking finance companies willing to lend and person in need of borrowing. The final decision as regards lending and borrowing is also
                            subject to the choice of the respective Users in their respective capacities as lenders / borrowers / users of other financial products as set out in the Platform. rupeemojo, through its Platform displays various products provided
                            by its partners or third parties. In addition to the foregoing, rupeemojo also provides certain other services with respect to data analytics subject to these Terms & Conditions. </p>


                        <p>In the event You wish to avail any of the Products displayed on the Platform, You will be required to provide certain information and our representatives may contact You through phone or email to understand Your requirements.
                        </p>


                        <p>rupeemojo provides various services, including but not limited to (“Services”): </p>


                        <p>Facilitating purchase and sale of Product between the Users on its Platform; </p>

                        <p>Assisting its customers to obtain a Product from various banks who are partnered with rupeemojo; </p>

                        <p>Assisting its Users to obtain their credit report through authorized agents partnered </p>

                        <p>with rupeemojo. rupeemojo will monitor and update the credit report obtained by the customer through the use of the Platform as and when rupeemojo receives such report from the authorized agents; </p>
                        <p>Provision of support to the Users in verifying the financial capabilities of Users; </p>

                        <p>rupeemojo will also provide You with spend analysis which analyses Your income and expenses which is obtained by rupeemojo by way of bank SMS scraping and through its integration with service provider. </p>

                        <p>Provision of a social score based on User’s Facebook, Twitter, Linkedin or any other social media accounts that are linked with User’s account on the Platform. </p>

                        <p>In the event You have registered Your phone number on the ‘Do Not Disturb’ registers with Your network provider, You shall ensure to take all steps to enable the Company’s representative’s to contact You via phone to provide details
                            about different financial products and You shall ensure that such calls received by You are pursuant to You providing Us with information and You shall not register a compliant with the relevant authorities for the same. </p>


                        <p>For the provision of the above mentioned Services, rupeemojo will be using and sharing the information of the User with multiple financial institutions and other parties, as may be required for the purpose mentioned above and rupeemojo
                            shall also run multiple credit checks to be able to get the best available offers to suit the needs of the Users. The collection, storing, use and transfer of information shared by the User shall be governed in accordance with
                            the Privacy Policy. </p>


                        <h3 style="font-weight: bold"> 2. ELIGIBILITY </h3>

                        <p>The User of this Platform unequivocally declares and agrees that the User is a natural / legal person who has attained the age of at least 18 years and is legally allowed to enter into a contract in India. The User is allowed to
                            utilize the Services in accordance with the terms and conditions detailed hereinafter. By registering on the Platform, the User hereby undertakes to agree and abide by the Terms & Conditions detailed herein. If the User violates
                            any of these Terms & Conditions, or otherwise violates an agreement entered into through the medium of the Platform, rupeemojo may terminate the User’s membership, delete his/her profile and any content or information posted
                            online by the User on the Platform and / or prohibit the User from using or accessing the Platform at any time in its sole discretion, with or without notice, including without limitation if rupeemojo believes that User is
                            under the age of 18 years. </p>


                        <h3 style="font-weight: bold"> 3. ACCEPTANCE OF TERMS AND CONDITIONS </h3>

                        <p>As a User of this Platform, You have agreed to the Terms & Conditions provided hereunder or anywhere else on this Platform including but not limited to disclaimers on this Platform. You are advised to read and understand the said
                            Terms & Conditions and in case You do not wish to agree to these Terms & Conditions, please refrain from using the Platform. </p>


                        <p>rupeemojo reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms & Conditions, at any time without any prior written notice to the User. It is the User’s responsibility to review these
                            Terms & Conditions periodically for any updates/ changes. User’s continued use of the App or Website following the modification of these Terms and Conditions will imply the acceptance by the User of the revisions.
                        </p>


                        <h3 style="font-weight: bold"> 4. YOUR ACCOUNT </h3>

                        <p>If You wish to use the Platform, You are required to maintain an account and will be required to furnish certain information and details, including Your name, email id, contact number and any other information deemed necessary
                            by rupeemojo and as further detailed in the Privacy Policy. You are responsible for maintaining the confidentiality and security of Your account, password, activities that occur in or through Your account and for restricting
                            access to Your computer to prevent unauthorized access to Your account. You agree to accept responsibility for all activities that occur under Your account or password. You should take all necessary steps to ensure that the
                            password is kept confidential and secure and should inform us immediately if You have any reason to believe that Your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized
                            manner. Please ensure that the details You provide Us with are true, correct, accurate and complete. Upon rupeemojo gaining knowledge of having any reasonable suspicion that the information provided by You is wrong, inaccurate
                            or incorrect, rupeemojo shall immediately terminate Your account without any notice to You in this regard. In the event of any changes to the information shared by You at the time of registering Yourself on the Platform, You
                            shall be responsible for forthwith notifying the said changes to Us. </p>


                        <p>You can access and update most of the information You provided us on the ‘dashboard’ area of Your account after You log-in or by writing to Us at info@rupeemojo.in. The right to use this Platform is personal to the User and is
                            not transferable to any other person or entity. </p>


                        <p>You shall at all times abide by the Terms and Conditions stated herein and any breach of these conditions may also lead to rupeemojo terminating Your account and appropriate civil and criminal remedies will be sought against You
                            as provided under the laws of India. </p>


                        <h3 style="font-weight: bold"> 5. PRIVACY </h3>

                        <p>rupeemojo collects certain information from You in order to provide the Services. rupeemojo’s use of Your personal information is governed in accordance with the Privacy Policy.</p>


                        <h3 style="font-weight: bold"> 6. LICENSE AND ACCESS </h3>

                        <p>rupeemojo grants You a limited license to access and use the Platform for availing the Services, but not to download any material from it (other than page caching) or modify it, or any portion of it, except with express written
                            consent of rupeemojo and / or its affiliates, as may be applicable. Any unauthorized access to the Platform or any networks, servers or computer systems connected to Platform and any attempt to modify, adapt, translate or reverse
                            engineer any part of the Platform or re-format or frame any portion of the pages of the Platform, save to the extent expressly permitted by these Terms & Conditions, is not permitted. This license is non-transferable and does
                            not permit any resale or commercial use of this Platform or its contents; any downloading or copying of account information for the benefit of anyone other than Your use; or any use of data mining, robots, or similar data gathering
                            and extraction tools. The Platform or any portion of the Platform (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold,
                            visited, distributed or otherwise exploited for any commercial purpose without express written consent of rupeemojo and / or its affiliates, as may be applicable. Any unauthorized use of the Platform shall terminate the permission
                            or revoke the license granted by rupeemojo. </p>


                        <p>You are permitted to use content delivered to You through the Service only on the Service. You may not copy, reproduce, distribute, or create derivative works from this content. Further, You agree not to reverse engineer or reverse
                            compile any of the Service technology, including but not limited to, any Java applets associated with the Service. </p>


                        <h3 style="font-weight: bold"> 7. MONITORING OF THE PLATFORM AND YOUR ACCOUNT </h3>

                        <p>rupeemojo has the right and liberty to monitor the content of the Platform at all times which shall include information provided in Your account. The monitoring of the Platform / Platform is important to determine the veracity
                            of the information provided by You and that every User remains in consonance with the Terms & Conditions provided herein. Subject to the Terms & Conditions mentioned herein, rupeemojo shall also have the liberty to remove any
                            objectionable content which is in contravention of the Terms & Conditions herein or share such information with any governmental authority as per procedures laid down by the law for the time being in force in India.
                        </p>


                        <p> We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset
                            limit mutually agreed by us with our acquiring bank from time to time. </p>


                        <h3 style="font-weight: bold"> 8. SERVICE SUSPENSION </h3>

                        <p> rupeemojo may wish to stop providing the Services, and may terminate use of it at any time without giving notice of termination to You. Unless rupeemojo informs the User otherwise, upon any termination, (a) the rights and licenses
                            granted to You in these terms will end; and (b) User must stop using the Platform forthwith. rupeemojo reserves the right to suspend or cease providing any Service and shall have no liability or responsibility to the User in
                            any manner whatsoever if it chooses to do so. </p>


                        <h3 style="font-weight: bold"> 9. PROHIBITED USES </h3>

                        <p> In addition to other prohibitions as set forth in the Terms & Conditions, You are prohibited from using the Platform: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate
                            any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse,
                            insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to
                            upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h)
                            to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the
                            Service or any related website, other websites, or the Internet. We reserve the right to terminate Your use of the Service or any related website for violating any of the prohibited uses. </p>


                        <h3 style="font-weight: bold"> 10. LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES </h3>

                        <p> Notwithstanding anything to the contrary contained herein, neither rupeemojo nor its affiliated companies, subsidiaries, officers, directors, employees or any related party shall have any liability to You or to any third party
                            for any indirect, incidental, special or consequential damages or any loss of revenue or profits arising under or relating to these Terms & Conditions. To the maximum extent permitted by law, You waive, release, discharge and
                            hold harmless rupeemojo, its affiliated and subsidiary companies, and each of their directors, officers, employees, and agents, from any and all claims, losses, damages, liabilities, expenses and causes of action arising out
                            of Your use of the Platform. </p>


                        <p> As a User, You also acknowledge that rupeemojo has maintained and carried out all possible checks and balances to assure the credibility of the Users as listed on the Platform. The information provided on the Platform is based
                            on the information that is provided to rupeemojo. In case the User does not disclose any information to rupeemojo, and the same is not found out at the first instance by rupeemojo despite all measures adopted to certify the
                            ratings or credibility of a User, rupeemojo shall not be responsible for any claims or liabilities. rupeemojo in any event is not responsible for any default in return of money that is borrowed from the lender and the lender
                            shall have the exclusive right to initiate proceedings against the defaulter borrower. rupeemojo shall to the best of its abilities aid the concerned User in terms of provision of information to initiate any legal proceedings.
                            However, rupeemojo shall not be responsible for any default on behalf of the User. rupeemojo is an information platform and the User is entitled to conduct its own diligence before taking any steps to initiate the processes
                            outlined in the Website. </p>


                        <p> rupeemojo does not make any representations or warranties on behalf of the financial service providers, banks or NBFCs partnered with rupeemojo with respect to the financial products / loans provided by such partners. rupeemojo
                            shall be responsible only to the extent of providing the Services and all liabilities and obligations of the customers / User with respect to the financial products provided by the partners shall be governed by the separate
                            agreement executed between such financial partners and the customers and rupeemojo shall have no liability in this regard whatsoever. </p>


                        <p> rupeemojo makes no representations or warranties about the accuracy, reliability, completeness, current-ness and/or timeliness of any content, information, software, text, graphics, links or communications provided on or through
                            the use of the Platform or that the operation of the Platform will be error free and/or uninterrupted. Consequently, rupeemojo assumes no liability whatsoever for any monetary or other damage suffered by You on account of the
                            delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Platform; and/or any interruption or errors in the operation of the Platform. This limitation of liability
                            clause shall prevail over any conflicting or inconsistent provision contained in any of the documents / content comprising this Terms & Conditions. It is up to You to take precautions to ensure that whatever You select for
                            Your use is free of such items as viruses, worms, malware, Trojan horses and other items of a destructive nature. </p>


                        <p> your use of the service and all information, products and other content (including that of third parties) included in or accessible from the service is at your sole risk. the service is provided on an "as is" and "as available"
                            basis. company expressly disclaim all warranties of any kind as to the service and all information, products and other content (including that of third parties) included in or accessible from the service, whether express or
                            implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and noninfringement. company make no warranty that: (i) the service will meet your requirements, (ii) the service
                            will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material
                            purchased or obtained by you through the service will meet your expectations, or (v) any errors in the technology will be corrected and you are solely responsible for any damage to your computer system or loss of data that
                            results from the download of any such material. no advice or information, whether oral or written, obtained by you from company or service provider through or from the service will create any warranty not expressly stated in
                            these terms. </p>


                        <p> you further agree that neither company or third party service provider nor any of their affiliates, account providers or any of their affiliates will be liable for any harms, which lawyers and courts often call direct, indirect,
                            incidental, special, consequential or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, even if company or service provider has been advised of the
                            possibility of such damages, resulting from: (i) the use or the inability to use the service / platform; (ii) the cost of getting substitute goods and services, (iii) any products, data, information or services purchased or
                            obtained or messages received or transactions entered into, through or from the service; (iv) unauthorized access to or alteration of your transmissions or data; (v) statements or conduct of anyone on the service; even if the
                            provider has been advised previously of the possibility of such damages; or (vii) any other matter relating to the service. the maximum liability of rupeemojo shall be limited to the amount actually paid by you to the company
                            for the service. </p>


                        <h3 style="font-weight: bold"> 11. INDEMNITY </h3>

                        <p> You agree to indemnify, save, and hold rupeemojo, its affiliates, contractors, employees, officers, directors, agents and its third party associates, licensors, and partners harmless from any and all claims, demands, losses, damages,
                            and liabilities, costs and expenses, including without limitation legal fees and expenses, arising out of or related to Your use or misuse of the Services or of the Website or App or Platform, any violation by You of these
                            Terms & Conditions, or any breach of the representations, warranties, and covenants made by You herein or Your infringement of any intellectual property or other right of any person or entity, or as a result of any threatening,
                            libelous, obscene, harassing or offensive material posted/ transmitted by You on the Platform. rupeemojo reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required
                            to indemnify rupeemojo, including rights to settle, and You agree to cooperate with rupeemojos’ defense and settlement of these claims. rupeemojo will use reasonable efforts to notify You of any claim, action, or proceeding
                            brought by a third party that is subject to the foregoing indemnification upon becoming aware of it. This paragraph shall survive termination of this Terms & Conditions. </p>


                        <h3 style="font-weight: bold"> 12. ADVERTISERS/ THIRD PARTY LINKS ON THE PLATFORM </h3>

                        <p> rupeemojo accepts no responsibility for advertisements contained within the Platform. rupeemojo has no control over and accepts no responsibility for the content of any website or mobile application to which a link from the Platform
                            exists. Such linked websites and mobile applications are provided “as is” for User’s convenience only with no warranty, express or implied, for the information provided within them. rupeemojo does not provide any endorsement
                            or recommendation of any third party website or mobile application to which the Platform provides a link. Further, the Users consent and agree that the content provided in the Website and the App shall be synced and shall be
                            available in both mediums. </p>


                        <h3 style="font-weight: bold"> 13. USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS </h3>

                        <p> If, at rupeemojo’s request, You send certain specific submissions (for example contest entries) or without a request from Us, You send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email,
                            by postal mail, or otherwise (collectively, 'comments'), You agree that We may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that You forward to Us.
                            We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. </p>


                        <p> You agree that Your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that Your comments will not contain libelous
                            or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend
                            to be someone other than Yourself, or otherwise mislead Us or third-parties as to the origin of any comments. You are solely responsible for any comments You make and their accuracy. We take no responsibility and assume no
                            liability for any comments posted by You or any third-party. </p>


                        <p> You are licensing to the Company and its service providers, any information, data, passwords, materials or other content (collectively, “Content”) You provide through or to the Service. Company and service provider of Company may
                            use, modify, display, distribute and create new material using such Content to provide the Service to You. Company and Service Provider may also use, sell, license, reproduce, distribute and disclose aggregate, non-personally
                            identifiable information that is derived through Your use of the Service. By submitting Content, You automatically agree, or promise that the owner of such Content has expressly agreed that, without any particular time limit,
                            and without the payment of any fees, Company and its third party service provider may use the Content for the purposes set out above. As between Company and third party service provider, Company owns Your confidential account
                            information. </p>


                        <h3 style="font-weight: bold"> 14. ACKNOWLEDGEMENTS </h3>

                        <p> You acknowledge that rupeemojo is a platform bringing Users together and that the rupeemojo is not engaged in either grant of loan or borrowing any money or assistance for any financial products from any member using the rupeemojo
                            Platform. You acknowledge that the use of the financial products is entirely based on the negotiations between the Users and third party and neither rupeemojo nor its affiliates, contractors, employees, officers, directors,
                            agents and their third party associates, licensors and partners have any interest with regard to any financial products assistance procured by or to a registered User. You acknowledge that rupeemojo will not be responsible
                            for any claim or damage in case of use of the financial products. rupeemojo in no manner warrants or guarantees the performance of a third party service provider that is providing services through the App. You acknowledge that
                            rupeemojo in no manner guarantees that the Users have provided all the information on this App which is true and correct including his address, phone numbers etc. You acknowledge that it is Your responsibility to verify the
                            information about the person on the Platform and rupeemojo is in no manner liable if the information provided on this App is untrue or incorrect. You acknowledge that rupeemojo is in no manner responsible for any claim of money
                            or damages in the event one person fails to either grant loan or a person fails to repay the loan or misrepresents his financial status or commits a fraud or cheating or any other such illegal act. </p>


                        <h3 style="font-weight: bold"> 15. APPLICABLE LAWS </h3>

                        <p> Your use of this Platform and any Term & Conditions stated in this agreement is subject to laws of India. In case of any disputes arising out of the use of the Website, Courts of Bangalore will have exclusive jurisdiction.
                        </p>


                        <h3 style="font-weight: bold"> 16.POLICYS </h3>

                        <p> Refund and Cancellation PolicyOur focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation.
                            Please read the fine prints of each deal before buying it, it provides all the details about the services or the product you purchase.In case of dissatisfaction from our services, clients have the liberty to cancel their
                            projects and request a refund from us. Our Policy for the cancellation and refund will be as follows:Cancellation PolicyFor Cancellations please contact us from the contact us page.Requests received later than 2 business
                            days prior to the end of the current service period will be treated as cancellation of services for the next service period.Refund PolicyWe will try our best to create the suitable design concepts for our clients.In case
                            any client is not completely satisfied with our products we can provide a refund.If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payment gateway name
                            payments refund will be made to the same account. </p>


                        <h3 style="font-weight: bold"> 17. GRIEVANCES </h3>

                        <p> Users have complete authority to file a complaint/ share feedback if they are disappointed by services rendered by rupeemojo. They can give their complaint/ feedback in writing or by way of an email to the following: </p>


                        <!-- <p> Grievance Officer: Mr. Sharat Konatham </p> -->


                        <p> Email: support@rupeemojo.com </p>







                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->

<!-- <app-account-button></app-account-button> -->