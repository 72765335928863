<!-- Start Ready To Talk Area -->
<section class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about rupeemojo</p>
            <a href="#" class="btn btn-primary">Contact Us</a>
            <!-- <span><a href="#">Or, get started now with a free trial</a></span> -->
        </div>
    </div>
</section>
<!-- End Ready To Talk Area -->