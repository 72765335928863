<!-- Start Account Create Area -->
<section class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Apply for a loan in minutes</h2>
            <p>Get your rupeemojo loan today!</p>
            <a href="https://play.google.com/store/apps/details?id=com.rupeemojo.application" target="_blank" class="btn btn-primary">Get Your rupeemojo Account</a>
        </div>
    </div>
</section>
<!-- End Account Create Area -->