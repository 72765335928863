<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <!-- <div class="page-title-content">
            <h2>Our Features</h2>
            <p>All the tools you need</p>
        </div> -->
    </div>
</div>
<!-- End Page Title Area -->

<section class="features-section ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Our Features</h2>
            <div class="bar"></div>
            <!-- <p>rupeemojo serves better satfication </p> -->
        </div>
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-box-list">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon">
                                    <i class="flaticon-settings"></i>
                                </div>

                                <h3>Instant Repeat Loans</h3>
                                <p>Repeat customers will get instant approval based upon repayment history</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-f78acb">
                                    <i class="flaticon-envelope-of-white-paper"></i>
                                </div>

                                <h3>Completely Digital</h3>
                                <p>From documentation to verification, every step of your application is fully digital</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-cdf1d8">
                                    <i class="flaticon-menu"></i>
                                </div>

                                <h3>Quick Approvals & Disbursement</h3>
                                <p>Get Instant Disbursal into your bank account.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-c679e3">
                                    <i class="flaticon-info"></i>
                                </div>

                                <h3> Safe & Secure</h3>
                                <p>Your data & all transactions are secured via a 128-bit SSL encryption</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>