<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <p>The rupeemojo story</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <!-- <span>How we were founded</span> -->
                    <h2>About us</h2>
                    <p>Rupeemojo is a mobile smart credit financial management platform that uses modern technology and big data risk control mechanisms to provide high-quality Customers with micro and short- term financial credit loan services. We committed
                        to providing customers with the best solutions from 1,000 to 50,000 rupees.
                    </p>
                    <!-- <p>Every month they moved their money the old way – which wasted their time and money. So they invented a beautifully simple workaround that became a billion-dollar business.</p> -->
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">

                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Team Area -->


<!-- <section class="team-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Meet our team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team-img4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>John Capabel</h3>
                        <span>Programer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<!-- Start Ready To Talk Area -->
<!-- <section class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about rupeemojo</p>
            <a href="#" class="btn btn-primary">Contact Us</a>
            <span><a href="#">Or, get started now with a free trial</a></span>
        </div>
    </div>
</section> -->
<!-- End Ready To Talk Area -->

<!-- <app-partner></app-partner> -->

<!-- Start App Download Area -->
<!-- <section class="app-download-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-image">
                    <div class="main-image">
                        <img src="assets/img/mobile-app1.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="image">
                        <img src="assets/img/mobile-app2.png" class="wow fadeInUp" data-wow-delay="0.9s" alt="image">
                    </div>

                    <div class="main-mobile-image">
                        <img src="assets/img/main-mobile.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="app-download-content">
                    <h2>You can find all the thing you need to payout</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>

                    <div class="btn-box">
                        <a href="#" class="app-store-btn">
                            <i class="flaticon-apple"></i> Download on
                            <span>App Store</span>
                        </a>

                        <a href="#" class="play-store-btn">
                            <i class="flaticon-play-store"></i> Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Download Area -->

<!-- Start Account Create Area -->
<!-- <section class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your rupeemojo account today!</p>
            <a href="#" class="btn btn-primary">Get Your rupeemojo Account</a>
        </div>
    </div>
</section> -->
<!-- End Account Create Area -->