<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <p>Our Latest News</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/1.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">The security risks of changing package owners</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/2.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Tips to Protecting Business and Family</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/3.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 19, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/4.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Business Debit Fees to Increase in 2019</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/5.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">10 Tips To Reduce Your Card Processing Costs</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/6.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 19, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">PayPal Here Card Reader Review 2019</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a href="#" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a href="#" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a href="#" class="page-numbers">3</a>
                    <a href="#" class="page-numbers">4</a>
                    <a href="#" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<app-account-button></app-account-button>
