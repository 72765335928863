<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <!-- <div class="page-title-content">
            <h2>Our Features</h2>
            <p>All the tools you need</p>
        </div> -->
    </div>
</div>
<!-- End Page Title Area -->
<!-- Start How It Works Area -->
<section class="how-it-works-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>How rupeemojo Works</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/1.png" alt="image">

                    <h3>1. Download from Google Play Store</h3>
                    <p>Go to Google Play Store an get the application download & install</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/2.png" alt="image">

                    <h3>2. Register on the our application</h3>
                    <p>Fill up your basic details in the application & get registered
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/3.png" alt="image">

                    <h3>3. Check Eligiblity</h3>
                    <p>Learn if you're eligible for an instant loan & Pre-approved credit limit</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/4.png" alt="image">

                    <h3>4. Upload & Verify your identity</h3>
                    <p>Upload all the necessary documents and complete your KYC verification digitally</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/5.png" alt="image">

                    <h3>5. Select Your Loan Plan</h3>
                    <p>Select a suitable instant loan offer and repayment term from multiple options</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/6.png" alt="image">

                    <h3>6. Receive Funds in Bank</h3>
                    <p>Get the loan amount credited into your account within 24 hours of approval </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End How It Works Area -->