<!-- Start Feedback Area -->
<section class="feedback-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>What customers say about Us</h2>
            <div class="bar"></div>
            <p>See What Our Customers Have to Say About Us </p>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel" [config]="imagesSlider">
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/4.jpg" alt="image">
                                </div>

                                <h3>Shiva</h3>
                                <span>Self Employee</span>
                                <span>Hyderabad</span>
                                <p>rupeemojo is an excellent app. I got my loan approved in one day itself. We just have to complete all the procedures correctly, and submit our correct bank account details. It is very useful.
                                </p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/2.jpg" alt="image">
                                </div>

                                <h3>Sharath</h3>
                                <span>Student</span>
                                <span>Hyderabad</span>
                                <p>This is an amazing personal loan app. I first became eligible for a loan of ₹5,000 and after I repaid it on time, I could then borrow ₹8,000. If I repay on time, I can borrow ₹10,000 next! rupeemojo is very helpful this
                                    way.
                                </p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/3.jpg" alt="image">
                                </div>

                                <h3>Swetha Naidu</h3>
                                <span>Salaried</span>
                                <span>Hyderabad</span>
                                <p>It's the best for the needy people of an emergency purpose.in a few step it approved the loan and amount will be credited to the account.i recommend everyone to install the app.it really help people and give more time to
                                    repay the amount.thank u rupeemojo for best service.</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/5.jpg" alt="image">
                                </div>

                                <h3>Venkat Rathod</h3>
                                <span>Salaried</span>
                                <span>Hyderabad</span>
                                <p>Great experience using this app. Availing loans was much easier and the installments were easy to keep a track of. Would totally recommend it to others.</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/1.jpg" alt="image">
                                </div>

                                <h3>Kavya</h3>
                                <span>Salaried</span>
                                <span>Warangal</span>
                                <p>online loan platforms are really easy to use and rupeemojo is the best of them all with in few minutes we can take instant loans</p>
                            </div>
                        </div>

                        <!-- <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/1.jpg" alt="image">
                                </div>

                                <h3>Steven Smith</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/2.jpg" alt="image">
                                </div>

                                <h3>Steven Lucy</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>

                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/3.jpg" alt="image">
                                </div>

                                <h3>John Terry</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div> -->
                    </ngx-slick-carousel>
                </div>
            </div>

            <div class="client-thumbnails">
                <div>
                    <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel" [config]="thumbnailsSlider">
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/1.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/2.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/3.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/4.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/5.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/1.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/2.jpg" alt="client"></div>
                        </div>

                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/3.jpg" alt="client"></div>
                        </div>
                    </ngx-slick-carousel>
                </div>

                <button class="prev-arrow slick-arrow">
                    <i class="fas fa-arrow-left"></i>
                </button>

                <button class="next-arrow slick-arrow">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->