<!-- Start Main Banner Area -->
<div class="main-banner-section jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Get Instant Loans of up to ₹50,000 From Anywhere In India</h1>
                            <p>Entirely Paperless Application | Directly Bank Transfer | Completely Safe & Secure | Low Monthly Interest Rates Based On Profile</p>
                            <a href="https://play.google.com/store/apps/details?id=com.rupeemojo.application" rel="noopener noreferrer" target="_blank" class="btn btn-primary">Download now</a>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6 col-md-12">
                        <div class="money-transfer-form">
                            <form>
                                <div class="form-group">
                                    <label>You Send</label>
                                    <div class="money-transfer-field">
                                        <input type="text" class="form-control" autocomplete="off" value="1,000">

                                        <div class="amount-currency-select">
                                            <select>
                                                <option>USD</option>
                                                <option>EUR</option>
                                                <option>GBP</option>
                                                <option>BRL</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="currency-info">
                                    <div class="bar"></div>
                                    <span><strong>62.3</strong> Exchange Rate</span>
                                    <span><strong>$4.50</strong> Transition Fees</span>
                                </div>

                                <div class="form-group">
                                    <label>Recipient gets</label>
                                    <div class="money-transfer-field">
                                        <input type="text" class="form-control" autocomplete="off" value="1,000">

                                        <div class="amount-currency-select">
                                            <select>
                                                <option>USD</option>
                                                <option>EUR</option>
                                                <option>GBP</option>
                                                <option>BRL</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="money-transfer-info">
                                    <span>You could save vs banks <strong>1,010.32 USD</strong></span>
                                </div>

                                <button type="submit" class="btn btn-primary">Get Started</button>

                                <div class="terms-info">
                                    <p>By clicking continue, I am agree with <a href="#">Terms & Policy</a></p>
                                </div>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Boxes Area -->
<section class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>

                        <h3>Instant Eligibility Check</h3>
                        <p>Check if you're eligible for an instant loan in 2 mins and get the money in 24 hrs</p>

                        <!-- <a href="features-1.html" class="read-more-btn">Read More</a> -->
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>

                        <h3>Fully Digitally</h3>
                        <p>From documentation to verification, every step of your application is fully digital</p>

                        <!-- <a href="features-1.html" class="read-more-btn">Read More</a> -->
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="flaticon-wallet"></i>
                        </div>

                        <h3>Instant Repeat Loans</h3>
                        <p>For Repeat loans from rupeemojo take just a few minutes.</p>

                        <!-- <a href="features-1.html" class="read-more-btn">Read More</a> -->
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>

                        <h3>Safe and Secure</h3>
                        <p> Your data & all transactions are secured via a 128-bit SSL encryption</p>

                        <!-- <a href="features-1.html" class="read-more-btn">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Boxes Area -->

<!-- Start How It Works Area -->
<section class="how-it-works-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>How rupeemojo Works</h2>
            <div class="bar"></div>
            <p>Follow our simple and easy steps to get a loan</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/1.png" alt="image">

                    <h3>1. Download from Google Play Store</h3>
                    <p>Go to Google Play Store an get the application download & install</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/2.png" alt="image">

                    <h3>2. Register on the our application</h3>
                    <p>Fill up your basic details in the application & get registered
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/3.png" alt="image">

                    <h3>3. Check Eligiblity</h3>
                    <p>Learn if you're eligible for an instant loan & Pre-approved credit limit</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/4.png" alt="image">

                    <h3>4. Upload & Verify your identity</h3>
                    <p>Upload all the necessary documents and complete your KYC verification digitally</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/5.png" alt="image">

                    <h3>5. Select Your Loan Plan</h3>
                    <p>Select a suitable instant loan offer and repayment term from multiple options</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/6.png" alt="image">

                    <h3>6. Receive Funds in Bank</h3>
                    <p>Get the loan amount credited into your account within 24 hours of approval </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End How It Works Area -->

<!-- Start Services Area -->
<section class="services-area ptb-70 pt-0">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <!-- <span class="sub-title">Fast & Easy Online Banking</span> -->
                    <h2>Transparent Eligibility Criteria</h2>
                    <div class="bar"></div>
                    <p>All you need to be eligible for a rupeemojo loan. </p>

                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Resident of India</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Age: 21 years to 60 years</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Employment Type: Student, Salaried and Self-employed</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Salaried individuals with a minimum salary of ₹18,000 (Metro Cities) and ₹15,000 (Non-metros)</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Location: We are active in 40+ cities</span></li>
                    </ul>

                    <a href="https://play.google.com/store/apps/details?id=com.rupeemojo.application" target="_blank" class="btn btn-primary">Check Eligibility Now</a>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/4.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->
<!-- Start Services Area -->
<!-- <section class="services-area ptb-70 pt-0">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <span class="sub-title">Fast & Easy Online Banking</span>
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>

                    <a href="#" class="btn btn-primary">Apply Now</a>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/4.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/5.png" alt="image">

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <!-- <span class="sub-title">Top Security</span> -->
                    <h2>Hassle-Free Documentation </h2>
                    <div class="bar"></div>
                    <p>To get a personal loan instantly, you should keep some documents handy before you start applying.
                    </p>

                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Proof of Identity : PAN Card OR Aadhar Card</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Proof of Address : Rental agreement, Utility or Postpaid bills</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Proof of Income : Last 3 months bank Statements </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Income Tax Return Verification Form for the last 2 years if you’re self-employed</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A Selfie</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Vaild Bank Account Details with Your Name</span></li>
                    </ul>

                    <a href="https://play.google.com/store/apps/details?id=com.rupeemojo.application" target="_blank" class="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <section class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <span class="sub-title">Price Transparency</span>
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>

                    <a href="#" class="btn btn-primary">Create Account</a>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/6.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <section class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/7.jpg" alt="image">
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <span class="sub-title">Automated Accounting</span>
                    <h2>Save 24 hours per week on accounting</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>

                    <a href="#" class="btn btn-primary">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Services Area -->

<!-- Start Comparisons Area -->
<!-- <section class="comparisons-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Compare us with others</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="comparisons-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Packages</th>
                        <th scope="col">Freelancer</th>
                        <th scope="col">Householder</th>
                        <th scope="col">Business Man</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Control payout timing</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Transparent payouts</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Automate evidence submission</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Collaboration notes</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Deposit tagging</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Technical support over IRC</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>24×7 support</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section> -->
<!-- End Comparisons Area -->

<!-- Start Features Area -->
<section class="features-section ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Our Features</h2>
            <div class="bar"></div>
            <!-- <p>rupeemojo serves better satfication </p> -->
        </div>
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-box-list">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon">
                                    <i class="flaticon-settings"></i>
                                </div>

                                <h3>Instant Repeat Loans</h3>
                                <p>Repeat customers will get instant approval based upon repayment history</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-f78acb">
                                    <i class="flaticon-envelope-of-white-paper"></i>
                                </div>

                                <h3>Completely Digital</h3>
                                <p>From documentation to verification, every step of your application is fully digital</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-cdf1d8">
                                    <i class="flaticon-menu"></i>
                                </div>

                                <h3>Quick Approvals & Disbursement</h3>
                                <p>Get Instant Disbursal into your bank account.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-c679e3">
                                    <i class="flaticon-info"></i>
                                </div>

                                <h3> Safe & Secure</h3>
                                <p>Your data & all transactions are secured via a 128-bit SSL encryption</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Invoicing Area -->
<!-- <section class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/1.png" class="wow zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/2.png" class="wow fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/3.png" class="wow fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/4.png" class="wow fadeInRight" data-wow-delay="1s" alt="image">
                    </div>

                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>

                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Invoicing Area -->

<!-- Start Fun Facts Area -->
<!-- <app-funfact></app-funfact> -->
<!-- End Fun Facts Area -->

<app-feedback></app-feedback>
<!-- <app-talk-button></app-talk-button> -->
<!-- <app-partner></app-partner> -->
<!-- <app-download></app-download> -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>The News from Our Blog</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/1.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">The security risks of changing package owners</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/2.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Tips to Protecting Business and Family</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/3.jpg" alt="image">
                        </a>

                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 19, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

                        <span>by <a href="#">admin</a></span>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start Account Create Area -->
<app-account-button></app-account-button>
<!-- End Account Create Area -->